<template>
  <div>
    <calendar v-if="user"></calendar>
    <div class="calendar-loader-container" v-if="!user">
      <loader></loader>
    </div>
  </div>
</template>

<script>
import Calendar from "./calendar";
import { mapState } from "vuex";

export default {
  name: "calendarMiddleware",
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  components: {
    Calendar,
  },
};
</script>
<style scoped lang="scss">
.calendar-loader-container {
  width: 100%;
  height: 65vh;
  position: relative;

  .loader {
    position: absolute;
    background-color: transparent;
  }
}
</style>
